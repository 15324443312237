import { requireSource } from '@/utils';
import { observer } from 'mobx-react';
import type { Model } from '../model';

const ModelCard = ({ store }: { store: Model }) => {
  const userInfo = store.modelCardData;
  // 姓名
  const name = userInfo?.name ?? null;

  // 性别
  const sex = userInfo?.sex === 1 ? '男' : userInfo?.sex === 2 ? '女' : null;
  // 年龄
  const age = userInfo?.age ? `${userInfo?.age}岁` : null;
  // 身高
  const height = userInfo?.height ? `${userInfo?.height}cm` : null;
  // 体重
  const weight = userInfo?.weight ? `${userInfo?.weight}kg` : null;
  // 城市
  const city = userInfo?.userJobHuntVO?.cityName ?? null;
  // 直播年限
  const liveYear = userInfo?.workYear ?? null;
  // gmv
  const gmv = userInfo?.userJobHuntVO?.gmv ?? null;
  // 最高人数
  const onLineCount = userInfo?.userJobHuntVO?.onLineCount ?? null;
  // 播过的类目
  const oldLiveType = userInfo?.userJobHuntVO?.oldLiveTypeList
    ? userInfo?.userJobHuntVO?.oldLiveTypeList
        ?.map((item) => item.name)
        ?.slice(0, 3)
        ?.join('/')
    : null;
  // 切片
  const slice = userInfo?.userOpusListVO?.sliceList
    ? userInfo?.userOpusListVO?.sliceList?.map((item) => item.coverUrl)
    : null;
  // 形象照
  const image = userInfo?.userOpusListVO?.imageList
    ? userInfo?.userOpusListVO?.imageList?.map((item) => item.fileUrl)
    : null;

  // 图片end
  const end = Math.min(3 - (slice?.length || 0), image?.length || 0);
  // 播放按钮图标
  const playIcon = requireSource('/canvas/icon-video.png');
  // 标题图片
  const titleImage = requireSource('/canvas/modelCardTitle.png');

  // 基本信息
  const info = [sex, height, weight, age, city].filter(Boolean).join(' | ');

  return (
    <div className="fixed right-[-100%] top-[-100%] z-[-99] ">
      <div
        className="w-[375px] bg-white"
        id="modelCardElement"
      >
        <div className="flex items-end justify-between p-[30px_28px_0] ">
          <div>
            <div className="text-24 font-600 text-[#000] mb-2">{name || ''}</div>
            <div className="text-12 font-400 text-[#232323]">{info || ''}</div>
          </div>
          <div className="flex flex-col items-center">
            <img
              src={store.wxCode}
              alt=""
              className="w-[82px] h-[82px] object-cover"
            />
            <div className="text-12 font-400 text-[#666666]">扫码查看我的切片</div>
          </div>
        </div>
        {(liveYear || gmv || onLineCount || oldLiveType) && (
          <div className="text-12 leading-18 text-[#666] pt-6 px-[28px]">
            {liveYear && <div>直播年限：{liveYear}年</div>}
            {gmv && <div>单场最高GMV：{gmv}元</div>}
            {onLineCount && <div>最高在线人数：{onLineCount}人</div>}
            {oldLiveType && <div>播过的类目：{oldLiveType}</div>}
          </div>
        )}
        {image?.length > 0 && (
          <img
            src={image[0]}
            alt=""
            className="w-[375px] h-[375px] object-cover pt-6"
          />
        )}
        {(image?.length > 1 || slice?.length >= 1) && (
          <div className="grid grid-cols-3 px-3 pt-6 gap-[4.5px]">
            {slice?.length > 0 && (
              <div className="relative w-full h-[159px]">
                <img
                  src={slice?.[0]}
                  alt=""
                  className="w-full h-full object-cover"
                />
                <img
                  src={playIcon}
                  alt=""
                  className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[28px] h-[28px]"
                />
              </div>
            )}
            {image?.length > 1 &&
              image?.slice(1, end).map((item, index) => (
                <div
                  key={index.toString()}
                  className="relative w-full h-[159px]"
                >
                  <img
                    src={item}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
              ))}
          </div>
        )}
        {titleImage && (
          <div className="flex items-center justify-center py-6">
            <img
              src={titleImage}
              alt=""
              className="w-[129px] h-[11px] object-cover"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(ModelCard);
